import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import WithAnimation from '../WithAnimation/UseIntersect';
import { photos } from './photo-data';
import { styBg } from './styles';
import Wave from '../common/Wave';

import { YOUTUBE_EMBED, THE_BRIDE } from '@/constants';

function PhotoSection() {
  return (
    <>
      <div id="fh5co-testimonial" css={styBg}>
        <div className="container">
          <div className="row">
            <WithAnimation>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                  <h2 className="main-font color__primary">Our Moments</h2>
                </div>
              </div>
            </WithAnimation>
            <WithAnimation>
              <div style={{ padding: '16px' }}>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    title={THE_BRIDE}
                    width="100%"
                    height="315"
                    src={YOUTUBE_EMBED}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: '16px' }}
                  />
                </div>
              </div>
            </WithAnimation>
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <ImageGallery autoPlay={false} infinite lazyLoad items={photos} showBullets={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Wave style={{ marginBottom: '-2px' }} />
    </>
  );
}

export default PhotoSection;
